<template>
  <FormKit v-model="address" type="group" :name="name || 'address'">
    <div class="row">
      <FormKit
        v-model="entityTypeValue"
        type="select"
        name="type"
        :options="getCustomerTypes()"
      />
    </div>
    <FirstLastName v-if="entityTypeValue === 'private'" />
    <div v-else class="row">
      <div class="col-sm-6">
        <FormKit name="companyName" label="Firma*" validation="required" />
      </div>
      <div class="col-sm-6">
        <FormKit name="careOf" label="Ansprechpartner:in / Abteilung" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <FormKit name="street" label="Straße*" validation="required" />
      </div>
      <div class="col-sm-6">
        <FormKit
          name="houseNumber"
          label="Hausnummer*"
          validation="required"
          help='"10" oder "10b"'
        />
      </div>
    </div>
    <ZipAndTheCityInput
      :prefilled-zip-code="prefilledZipCode"
      :zip-input-disabled="zipInputDisabled"
      :is-custom-city-input-enabled="isCustomCityInputEnabled"
    />
  </FormKit>
</template>

<script setup lang="ts">
import ZipAndTheCityInput from "~/components/02_molecules/customer-area/ZipAndTheCityInput.vue";
import type { Address, EntityType } from "~/src/generated-sources/public";
import FirstLastName from "~/components/02_molecules/forms/FirstLastName.vue";
import getCustomerTypes from "~/utils/customer-area/getCustomerTypes";

const props = defineProps<{
  prefilledZipCode?: string;
  zipInputDisabled?: boolean;
  isCustomCityInputEnabled?: boolean;
  /** Wether or not the form should be prefilled with the given address */
  prefillFormWithAddress?: Address;
  defaultEntityType?: EntityType;
  name?: string;
}>();

const address = ref({ ...props.prefillFormWithAddress });

const entityTypeValue = ref<EntityType>(
  props.defaultEntityType || address.value.type || "private",
);
</script>
